import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import queryKeys from 'src/modules/DocumentsPrivate/constants/queryKeys';
import postVerifyDocumentByBlockchainAddress from 'src/modules/DocumentsPrivate/api/postVerifyDocumentByBlockchainAddress';
import { VerifyDocumentResponse } from 'src/modules/DocumentsPrivate/types';

type UsePostVerifyDocumentByBlockchainAddressParams = {
  blockchainAddress?: string;
};

type UsePostVerifyDocumentByBlockchainAddressResponse = VerifyDocumentResponse;

const usePostVerifyDocumentByBlockchainAddress = (
  { blockchainAddress }: UsePostVerifyDocumentByBlockchainAddressParams,
  options?: UseQueryOptions<
    UsePostVerifyDocumentByBlockchainAddressResponse,
    AxiosError
  >
) =>
  useQuery<UsePostVerifyDocumentByBlockchainAddressResponse, AxiosError>(
    [queryKeys.usePostVerifyDocumentByBlockchainAddress],
    async () => {
      if (!blockchainAddress) {
        throw new Error('BLOCKCHAIN_ADDRESS_NOT_SET');
      }

      const { data } = await postVerifyDocumentByBlockchainAddress(
        blockchainAddress
      );

      return data;
    },
    options
  );

export default usePostVerifyDocumentByBlockchainAddress;
