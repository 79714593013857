import { Accordion, AccordionDetails, Step, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import DocumentActiveDeliveryForm from 'src/modules/DocumentsPrivate/components/documentDetailsStep/components/DocumentActiveDeliveryForm/DocumentActiveDeliveryForm';
import { useActiveDeliveryState } from 'src/modules/DocumentsPrivate/components/documentSignatureForm/context/ActiveDeliveryStateProvider';
import { AgreementStatusEnum } from 'src/modules/DocumentsPrivate/constants/document';
import useDocumentHistoryContext from 'src/modules/DocumentsPrivate/hooks/useDocumentHistoryContext';
import DocumentHistoryDownload from 'src/modules/DocumentsPrivate/components/documentHistoryDownload';

import {
  StyledAccordionSummary,
  StyledAccordionWrapper,
  StyledRejectIcon,
  StyledSignIcon,
  StyledStepConnector,
  StyledStepLabel,
  StyledStepper,
  StyledWrapper
} from './styles';

type SignatureHistoryProps = {
  documentTitle: string;
  sendAcknowledgementEvent: () => void;
};

const SignatureHistory = ({
  documentTitle,
  sendAcknowledgementEvent
}: SignatureHistoryProps) => {
  const { t } = useTranslation();

  const { documentHistory } = useDocumentHistoryContext();

  const documentHistoryIsNotEmpty = documentHistory.length > 0;

  const mapStatusToTranslation = {
    [AgreementStatusEnum.ACCEPTED]: t(
      'PRIVATE_DOCUMENTS_DETAILS_SIGNING_ACCEPTED_DOCUMENT_SIGNING_STEPS_APPROVED'
    ),
    [AgreementStatusEnum.REJECTED]: t(
      'PRIVATE_DOCUMENTS_DETAILS_SIGNING_REJECTED_DOCUMENT_SIGNING_STEPS_REJECTED'
    ),
    [AgreementStatusEnum.WAITING_FOR_RECEIVER]: t(
      'PRIVATE_DOCUMENTS_DETAILS_SIGNING_ACCEPTED_DOCUMENT_SIGNING_STEPS_FORWARDED_BY_SENDER'
    ),
    [AgreementStatusEnum.ACCEPTED_DEPRECATED]: t(
      'PRIVATE_DOCUMENTS_DETAILS_SIGNING_ACCEPTED_DOCUMENT_SIGNING_STEPS_FORWARDED_BY_SENDER'
    ),
    [AgreementStatusEnum.REJECTED_DEPRECATED]: t(
      'PRIVATE_DOCUMENTS_DETAILS_SIGNING_ACCEPTED_DOCUMENT_SIGNING_STEPS_FORWARDED_BY_SENDER'
    ),
    [AgreementStatusEnum.ACCEPTED_WAITING_FOR_PUBLISHING]: t(
      'ACCEPTED_WAITING_FOR_PUBLISHING'
    ),
    [AgreementStatusEnum.REJECTED_WAITING_FOR_PUBLISHING]: t(
      'REJECTED_WAITING_FOR_PUBLISHING'
    ),
    [AgreementStatusEnum.ERROR]: t('PRIVATE_DOCUMENT_LIST_STATUS_ERROR'),
    [AgreementStatusEnum.DEFAULT]: t('TODO')
  };

  const { isDocumentAcknowledged, isConfirmationOfAcknowledgementEnabled } =
    useActiveDeliveryState();

  return (
    <StyledWrapper>
      {documentHistoryIsNotEmpty && (
        <>
          {isConfirmationOfAcknowledgementEnabled &&
            !isDocumentAcknowledged && (
              <DocumentActiveDeliveryForm
                sendAcknowledgementEvent={sendAcknowledgementEvent}
              />
            )}

          <Typography variant="subtitle1" fontWeight={600}>
            {t(
              'PRIVATE_DOCUMENTS_DETAILS_SIGNING_ACCEPTED_DOCUMENT_SIGNING_STEPS'
            )}
          </Typography>
          <StyledStepper
            activeStep={documentHistory?.length || 0}
            orientation="vertical"
            connector={<StyledStepConnector />}
          >
            {documentHistory.map(historyEntry => {
              const { signingStatus, publicationDate, blockchainAddress } =
                historyEntry;
              const isRejected =
                signingStatus === AgreementStatusEnum.REJECTED ||
                signingStatus ===
                  AgreementStatusEnum.REJECTED_WAITING_FOR_PUBLISHING;

              const formattedDate = moment
                .utc(publicationDate)
                .local()
                .format('DD.MM.YYYY HH:mm:ss');
              return (
                <Step key={`${blockchainAddress}_step`}>
                  <StyledStepLabel
                    StepIconComponent={
                      isRejected ? StyledRejectIcon : StyledSignIcon
                    }
                  >
                    <Typography variant="subtitle2" fontWeight={600}>
                      {signingStatus && mapStatusToTranslation[signingStatus]}
                    </Typography>
                    <Typography fontWeight={400} variant="caption2">
                      Data: {formattedDate}
                    </Typography>
                  </StyledStepLabel>
                </Step>
              );
            })}
          </StyledStepper>
          <Typography variant="subtitle1" fontWeight={600}>
            {t(
              'PRIVATE_DOCUMENTS_DETAILS_SIGNING_ACCEPTED_DOCUMENT_PROCES_HISTORY'
            )}
          </Typography>
          <StyledAccordionWrapper>
            {documentHistory.map(historyEntry => {
              const { signingStatus, publicationDate, blockchainAddress } =
                historyEntry;
              const formattedDate = moment
                .utc(publicationDate)
                .local()
                .format('DD.MM.YYYY HH:mm:ss');

              return (
                <Accordion key={`${blockchainAddress}_log`}>
                  <StyledAccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant="subtitle2">{formattedDate}</Typography>
                    <Typography variant="subtitle2">
                      {signingStatus && mapStatusToTranslation[signingStatus]}
                    </Typography>
                  </StyledAccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="body2"
                      fontWeight={600}
                      marginBottom={0.5}
                    >
                      {t(
                        'PRIVATE_DOCUMENTS_DETAILS_SIGNING_ACCEPTED/REJECTED_DOCUMENT_PROCES_HISTORY_PUBLICATION_DATE'
                      )}
                    </Typography>
                    <Typography variant="body1" marginBottom={2}>
                      {formattedDate}
                    </Typography>
                    <Typography
                      variant="body2"
                      marginBottom={1}
                      fontWeight={600}
                    >
                      {t(
                        'PRIVATE_DOCUMENTS_DETAILS_SIGNING_ACCEPTED_DOCUMENT_PROCES_HISTORY_TITLE'
                      )}
                    </Typography>
                    <Typography variant="body1" marginBottom={2}>
                      {documentTitle}
                    </Typography>
                    <DocumentHistoryDownload
                      blockchainAddress={blockchainAddress}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </StyledAccordionWrapper>
        </>
      )}
    </StyledWrapper>
  );
};

export default SignatureHistory;
