import { Typography } from '@mui/material';
import { useContext } from 'react';

import { LayoutContext } from 'src/contexts/LayoutContext';
import { ReactComponent as PoweredByIcon } from 'src/assets/poweredBy.svg';

import { StyledContainer, StyledContent } from './styles';

const PoweredBy = () => {
  const { isNavbarOpened } = useContext(LayoutContext);
  const appVersion = `v ${process.env.REACT_APP_VERSION}.${process.env.REACT_APP_PIPELINE_ID}`;

  return (
    <StyledContainer hasNavbar={isNavbarOpened}>
      <StyledContent>
        <Typography variant="caption1">
          Powered by <PoweredByIcon /> {appVersion}
        </Typography>
      </StyledContent>
    </StyledContainer>
  );
};

export default PoweredBy;
